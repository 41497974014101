<template>
  <div class="donatetoknow">
    <div class="donate-title">
    <span>捐献须知</span>
    </div>
    <div class="needknow-container">
        <h2 class="needknowtitle">{{donatetoknow.title}}</h2>
        <TitleLine width="400px"></TitleLine>
        <div class="needknowcontent">
            <ul>
                <li class="content2">
                    <dd>
                    <p v-html="donatetoknow.info?donatetoknow.info:''"></p>
                    <!-- <p>
                      <span>1、 造血干细胞志愿捐赠者有哪些健康标准？</span>
                      <span>凡年龄在18-45周岁，身体健康，经下列血液检查合格者，都可以成为造血干细胞捐赠者。</span>
                      <span>（1）丙氨酸氨基转移酶（ALT）≤50单位 </span>
                      <span>（2）乙型肝炎病毒表面抗原：阴性</span>
                      <span>（3）丙型肝炎病毒抗体：阴性</span>
                      <span>（4）艾滋病病毒抗体：阴性 </span>
                      <span>（5）梅毒试验：阴性 </span>
                      <span>（6）其他：符合献血要求</span>
                    </p>
                    <p>
                        <span>2、申请时做到深思熟虑，捐赠时义无反顾 </span>
                        <span>
                            世界上每个骨髓库都存在着一定比例的造血干细胞捐赠者临捐时反悔和流失。
                            降低流失率和反悔率有效的办法是，每一位志愿捐赠者的登记时要认真了解血液科学知识和捐赠造血干细胞的常识，
                            熟悉捐赠流程，做好配偶和直系亲属的思想工作，再填写《志愿捐赠造血干细胞登记表》，
                            以保证有需要进行造血干细胞移植的患者与其配型相合时能够义无反顾地实施捐赠。
                        </span>
                    </p>
                    <p>
                        <span>3、捐赠造血干细胞时无需捐赠者支付任何费用</span>
                        <span>
                            申请登记成为一名志愿捐赠者无需支付任何费用，就是有朝一日有幸与某患者配型成功，也不需要捐赠者支付任何费用。
                            这些费用是由政府彩票公益金资助的、团体单位及个人捐赠的、受者按国家标准规定缴纳的采集成本费等几部分组成。
                        </span>
                    </p>
                    <p>
                        <span>4、捐赠外周血造血干细胞全过程：</span>
                        <span>捐赠造血干细胞的过程可分为以下十个步骤。</span>
                    </p> -->
                    </dd>
                </li>
                <!-- <div class="block">
                <el-pagination
                    class="paginatedonate"
                    layout="prev, pager, next"
                    :total="100">
                </el-pagination>
                </div> -->
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import TitleLine  from '@/components/TitleLine'
export default {
  props: {
     donatetoknow: {
         type: Object,
         default: ""
     } 
  },
  components: {
    TitleLine
  },
  name: 'Donatetoknow'
}
</script>

<style lang="less" scoped>
.donatetoknow {
    .donate-title  {
        margin-top: 53px;
        font-size: 23px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        span {
            &:nth-child(1) {
            display: flex;
                &::before {
                content: "";
                display: block;
                height: 20px;
                width: 35px;
                background: url('../../../assets/img/title.png') no-repeat center center;
                }
            }
        }
    }
    .needknow-container {
        width: 100%;
        height: 1033px;
        overflow: auto;
        background: #ffffff;       
        box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
        .needknowtitle {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            margin: 40px 0 16px 0;
        }
        .titleborder {
            height: 6px;
            background: url('../../../assets/img/toknowborder.png') no-repeat center center;
        }
        .needknowcontent {
           width: 956px;
           min-height: 340px; 
           margin: 35px 123px 75px 121px;
           ul {
               width: 100%;
               height: 100%;
               li {
                   margin-bottom: 35px;
                   dt {
                     width: 108px;
                     height: 17px;
                     display: flex;
                     justify-content:space-between;
                     font-size: 17px;
                     color: #D1292E;
                     font-weight: bold;
                     margin-bottom: 23px;
                   }
                   dd {
                       color: #666666;
                       line-height: 36px;
                       p {
                           text-indent: 1em;
                       }
                   }
                   &:last-child {
                       margin-bottom: 0;
                   }

                   &:nth-child(1) {
                     dd {
                         p {
                             margin-top: 40px;
                             /* span {
                                 color: #D1292E;
                             } */
							 a {
								 color: blue;
								 cursor: pointer;
								 text-decoration: none;
							 }
                            &::before {
                               position: relative;
                               top: 13px;
                               left: 0px;
                               float: left;
                               content: "";
                               display: block;
                               width: 8px;
                               height: 8px; 
                               background: url('../../../assets/img/list-little.png') no-repeat center center;
                            }
                            &:first-child {
                                text-indent: 2em;
                               &::before {
                                   display: none;
                               }
                            }
                            
                         }
                     }
                   }
               }
               .content2 {
                   p {
                       margin-top: 40px;
                       span {
                           display: block;
                       }
                   }
               }
               .paginatedonate {
                   width: 354px;
               }
           }
        }
    }
}
</style>
